import React , {useState}from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import { Link } from "react-router-dom";
import "./Navbar.css"
const Navbar = () => {

    const [nav, setNav] = useState(true)

    const handleNav = () => {
        setNav(!nav)
    }

    return (
    <div className="flex justify-between items-center h-24 max-w-[100%] mx-auto px-4 sticky top-0 bg-[#EDEDED]/60 backdrop-blur-md nav-bar-main">
        <Link className="no-underline" to="/">
            <div className="flex flex-row px-16">
                <h1 className="w-full text-3xl text-[#E38486]">RE</h1>
                <h1 className="w-full text-3xl text-[#46131F]">ARRANGE.</h1>
            </div>
        </Link>
        <ul className="hidden md:flex px-16">
            <li className="p-4 text-[#46131F]"><Link className="text-[#46131F] no-underline hover:font-semibold" to="/tool">Værktøj</Link></li>
            <li className="p-4 text-[#46131F]">Kontakt</li>
        </ul>
        <div onClick={handleNav} className="block md:hidden">
            {nav ? <AiOutlineMenu size={20}/> : <AiOutlineClose size={20}/>}
        </div>
        <div className={nav ? 'fixed left-[-100%]' : 'fixed left-0 top-0 w-[50%] h-[300vh] border-r border-r-gray-500 bg-[#EDEDED] ease-in-out duration-500 md:hidden mobile-navbar'}>
            <div>
                <h1 className="flex justify-between items-center h-[10%] font-bold text-3xl mx-auto px-4 text-[#E38486] pt-12">RE</h1>
                <h1 className="flex justify-between items-center h-[10%] font-bold text-3xl mx-auto px-4 text-[#46131F] pt-2">ARRANGE.</h1>
            </div>
            <ul className="pt-14 uppercase p-4">
                <li className="p-4 border-b border-gray-600 text-[#46131F]">Disclaimer</li>
            </ul>
        </div>
    </div>
  )
}

export default Navbar