import React from 'react'
import Typed from 'react-typed'
import { useNavigate } from "react-router-dom";

const Hero = () => {

  const navigate = useNavigate()

  return (
    <div className="text-white pb-10">
        <div className="max-w-[800px] mt-[-96px] w-full h-[80vh] mx-auto text-center flex flex-col justify-center">
            {/*<p className="text-[#46131F] font-bold p-2 md:text-2xl sm:text-xl text-sm">MANAGE YOUR HOUSEHOLD EFFICIENTLY</p>*/}
            <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6 text-[#46131F] mt-0">Det skal være slut med madspild.</h1>
            <div className="flex justify-center py-4">
                <p className="md:text-3xl sm:text-xl text-sm font-bold text-[#46131F]">Søg efter opskrifter baseret på</p>
                <Typed
                className="md:text-3xl sm:text-xl text-sm font-bold pl-2 text-[#E38486]"
                strings={['dine madvarer', 'tidskriterier' ,'øvrige præferencer']}
                typeSpeed={140}
                backSpeed={140}
                loop />
            </div>
            <p className="md:text-2xl text-xl font-bold text-gray-500 py-2">Vi hjælper dig med at finde opskrifter fra populære madblogs baseret på de varer, du allerede har i hjemmet.</p>
            <button onClick={() => {navigate("/tool")}}className='bg-black w-[200px] rounded-md my-6 mx-auto py-3 text-[#E38486] font-bold border-2 border-black hover:bg-[#EDEDED] hover:text-[#46131F] hover:border-[#46131F]'>Prøv os!</button>
        </div>
    </div>
  )
}

export default Hero