import React from 'react'
import Cooking from '../Assets/CookingRed.svg'
import { useNavigate } from "react-router-dom";

const FoodService = () => {

  const navigate = useNavigate()

  return (
    <div className="w-full bg-white py-16 px-4">
        <div className='max-w-[1240px] mx-auto grid py-2 md:grid-cols-2'>
            <img className='w-[500px] mx-auto my-4 pl-2' src={Cooking} alt=""/>
            <div className="text-black pr-2 flex flex-col justify-center">
                <p className='md: text-2xl text-[#E38486] font-bold'>ET MADSPILD PÅ 247.000 TONS ÅRLIGT</p>
                <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Vi vil gerne hjælpe med at reducere det tal betragteligt</h1>
                <p>
                    I gennemsnit smider en dansk husholdning mad ud for 8.400 kr. årligt.
                    Det giver et samlet økonomisk tab på 13.500.000.000 kr. for alle husholdninger tilsammen. Fordelene ved at reducere madspild er altså mange. Både miljømæssige og økonomiske. Læs mere <a className="text-[#E38486]" href="https://www.ernaeringsfokus.dk/media/tjlpwzvf/2018-forbrugernes-madspild-i-danmark.pdf">her</a>.
                </p>
                <button onClick={() => {navigate("/tool")}}className='text-[#E38486] w-[200px] rounded-md my-6 mx-auto py-3 bg-black font-bold border-2 border-black hover:bg-black md:mx-0 hover:text-[#46131F] hover:border-[#46131F] hover:bg-white'>Start nu!</button>
            </div>
        </div>
    </div>
  )
}

export default FoodService