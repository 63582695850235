import axios from 'axios';


export const importantURL = {
    'API': 'https://recipe-api.rearrange.dk/',
    'LOCAL': 'http://localhost:8000'
}

export const api = axios.create({
    baseURL: importantURL.API
    /*baseURL: importantURL.API */
})