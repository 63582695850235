import React from 'react'
import Navbar from './Navbar'
import Hero from './Hero'
import FoodService from './Subsections/FoodService'
import AutoBanner from './Subsections/AutoBanner'
import Footer from './Subsections/Footer'
//import Newsletter from './Subsections/Newsletter'
//import Options from './Subsections/Options'

const LandingPage = () => {
  return (
    <div className="bg-[#EDEDED] text-white w-screen h-screen">
      <Navbar />
      <Hero />
      <FoodService />
      <AutoBanner />
      <Footer />
      {/* <Newsletter />
       <Options /> */}
    </div>
  )
}

export default LandingPage