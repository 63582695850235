import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ingredients from '../../../../constants/ingredients.js';

export default function IngredientSelect({ onChange }) {
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ marginTop: 2, width: 238, height: 50 }}
      options={ingredients}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => newValue == null ? onChange("") : onChange(newValue.label)}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Vælg en ingrediens"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
