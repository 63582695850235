
import './ToggleButton.css'

export default function ToggleButton({text, onChange, isChecked}){

    function handleClick(){
        const newVal = !isChecked;
        onChange(newVal);
    }


    return (
        <button
            className={`toggle-button ${isChecked ? 'checked' : ''}`}
            onClick={handleClick}>
            {text}
        </button>
      );

};
