import React, { useState, useContext, createContext } from 'react'

const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {

  const [user, setUser] = useState(null)
  const [registration_name, setRegistration_name] = useState("DEFAULT")

  const map_roles = (role) => {
    const roleHierarchy = {'FREE': 0, 'PREMIUM': 1, 'ADMIN': 2}
    return(roleHierarchy[role])
  }

  const login = (user) => {
    console.log("SETTING LOGIN")
    console.log(user)
    console.log("Role is displayed above.")
    setUser(user)
  }

  const logout = () => {
    setUser(null)
  }

  const register = (name) => {
    setRegistration_name(name)
  }

  return (
    <AuthContext.Provider value = {{user, registration_name, register, login, logout, map_roles}}>
      {children}
    </AuthContext.Provider>
  )

}

export const useAuthProvider = () => {

  return useContext(AuthContext)

}
