import './ResultsTextBox.css'

export default function ResultsTextBox(recipes){

    var txt = recipes.length === 0 ? "Ingen opskrifter fundet" : "Viser " + recipes.length + " opskrifter";

    return (
        <div className="wrapper-results-text-box">
            <text className="results-text">{txt}</text>
        </div>
    );



}