import { useState } from "react";
import "./DropDownFields.css"; // import CSS file for styling
import Select from 'react-select';
const style = getComputedStyle(document.body)


const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    marginTop: "5px",
    marginBottom: "5px",
    overflow:'hidden',
    

  }),
  menuList: (provided, state) => ({
    ...provided,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '0.5%',
    padding: '0.5%',
    overflow:'hidden',

  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: style.getPropertyValue("--layout-color-1"),
    padding: '3px 3px',
    fontSize: '0.9em',
    fontWeight: '400',
    margin: '1px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: 'white',
    fontWeight: '600',
    fontSize: '0.9em',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: 'white',
    '&:hover': {
      backgroundColor: style.getPropertyValue('--layout-color-red'),
      color: 'white',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '2px 2px',
    fontWeight: '500',
    width:"6em",
    margin: '1px',
    borderRadius: "4px",
    backgroundColor: state.isFocused ? style.getPropertyValue("--layout-color-1") : 'white',
    color: state.isFocused ? 'white' : 'black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  }),
};


export function DropDownSelect2({options, onChange}) {

  function addOption(choices) {
    onChange(choices.map(choice => choice.value));
  }

  return (
    <div className="dropdown-select-lib" >
      <Select
        closeMenuOnSelect={false}
        isMulti
        options={options}
        onChange={(choices) => addOption(choices)}
        menuPosition="fixed"
        menuPlacement="bottom"
        styles={customStyles}
        noOptionsMessage={() => null}
        isSearchable={false}
        placeholder="Vælg..."
      />
    </div>
  );
}


export function DropDownSelect({ options, onChange }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  function addOption(e) {
    e.preventDefault();
    const newOption = e.target.value;
    if (selectedOptions.includes(newOption))
      return;

    const updatedOptions = [...selectedOptions, newOption];
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  }

  function removeOption(optionToRemove) {
    const updatedOptions = selectedOptions.filter((option) => option !== optionToRemove);
    setSelectedOptions(updatedOptions);
    onChange(updatedOptions);
  }

  return (
    <div className="dropdown-select">
      <select className="dropdown-select-options"
              value=""
              onChange={(e) => addOption(e)}>
        <option disabled value="">Options</option>
        {options.map((option) => (
          <option key={option} value={option} onClick={(e) => e.preventDefault()}>
            {option}
          </option>
        ))}
      </select>
      {selectedOptions.length > 0 &&
       <div className="dropdown-selected-options">
        {selectedOptions.map((option) => (
          <div className="dropdown-selected-option-row" key={option}>
            {option}
            <button className="dropdown-selected-remove-button" onClick={() => removeOption(option)}>✖</button>
          </div>
        ))}
      </div> 
      }
    </div>
  );
}
