import React from 'react'
import Marquee from "react-fast-marquee";

/* Import images*/
import image1 from '../Assets/Mummum.png';
import image2 from '../Assets/Valdemarsro.png';
import image3 from '../Assets/Arla.png';

/* Import styles */
import './AutoBanner.css';

const AutoBanner = () => {

  return (
    <div className="w-full bg-white py-16 px-[5vh] flex flex-col items-center justify-center">
        <p className="md: text-2xl text-[#E38486] font-bold py-5">
            VI KAN IKKE GØRE DET SELV
        </p>
        <p className="text-black pt-2 pb-10 max-w-screen-sm text-center">
            Vi ved ikke så meget om mad. Det er der heldigvis mange andre, der gør. Hos <span className="text-[#E38486] font-bold">RE</span><span className="text-[#46131F] font-bold">ARRANGE</span> henter og organiserer vi opskrifter fra en række af Danmarks førende madblogs, så du kan få inspiration til at lave lækker mad med de varer, du allerede har i køleskabet. Der er <span className="underline">ikke</span> tale om et samarbejde. Derfor linker vi direkte til de forskellige udbydere, så de får den anerkendelse, de fortjener.
        </p>
        <Marquee className='marquee'>
            <a href="https://www.mummum.dk" className="cardLink" target="_blank" rel="noreferrer">
                <div className="card">
                    <h2>Mummum.dk</h2>
                    <div className="cardImage">
                        <img src={image1} width={80} height={80} alt=""/>
                    </div>
                </div>
            </a>
            <a href="https://www.valdemarsro.dk" className="cardLink" target="_blank" rel="noreferrer">
                <div className="card">
                    <h2>Valdemarsro.dk</h2>
                    <div className="cardImage">
                        <img src={image2} width={80} height={80} alt=""/>
                    </div>
                </div>
            </a>
            <a href="https://www.arla.dk/opskrifter" className="cardLink" target="_blank" rel="noreferrer">
                <div className="card">
                    <h2>Arla.dk</h2>
                    <div className="cardImage">
                        <img src={image3} width={80} height={80} alt=""/>
                    </div>
                </div>
            </a>

        </Marquee>
    </div>
  )
}

export default AutoBanner