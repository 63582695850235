import React, { useState } from "react";
import "./CardRecipe.css"

export default function DisplayRecipes( recipes )
{


  const cardIncremention = 20
  const [totalCards, setTotalCards] = useState(cardIncremention);

  const displayedRecipes = recipes.slice(0, totalCards);

  const increaseDisplayedCards = () => {
    setTotalCards(totalCards + cardIncremention);
  };
  


  return (
      recipes.length > 0 && (
        <div className="recipes-outer-container">
          <div className="recipes-container">
            {displayedRecipes.map((recipe) => (
              <CardRecipe
                img={recipe.image_rearrange}
                title={recipe.title}
                url={recipe.url}
                key={recipe.id}
              />
            ))}
          </div>
          {recipes.length > totalCards &&
            <button className="recipes-more-button" onClick={increaseDisplayedCards}>Vis flere...</button> }
        </div>
      )
  );
};




function CardRecipe(recipeProps)
{
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const onClickUrl = (url) => {
    return () => openInNewTab(url)
  }

  return(
    <div className='card-recipe-container'>
        <img className="card-recipe-image" src={recipeProps.img} loading="lazy" alt=" "/>
        <div className="card-recipe-container-name-link" >
          <div className="card-recipe-text">{recipeProps.title}</div>
          <button onClick={onClickUrl(recipeProps.url)} className="card-recipe-button">Gå til opskrift</button>
        </div>
    </div>

  )




}

