import React from 'react'

const Footer = () => {

  return (
    <div className="w-full bg-[#EDEDED] py-16 px-4 text-black flex justify-center">
        <p>Copyright @ Rearrange 2023</p>
    </div>
  )
}

export default Footer