import './TimeToggleContainer.css';
import React, { useState } from "react";
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          thumb: {
            color: '#250472', // Set your desired color here
          },
          track: {
            color: '#360e92', // Set your desired color here
          },
          rail: {
            color: '#7864ac', // Set your desired color here
          },
        },
      },
    },
  });


export function TimeSlider({timeCallback}){

    const [value, setValue] = useState(60);
    const handleChange = (event, newValue) => {

        if (newValue >= 57)
            timeCallback(60)
        else
            timeCallback(newValue)

        setValue(newValue);
      };

    

    return(
        <div className="time-slider-container">
        <ThemeProvider theme={theme}>
        <Slider
            value={value}
            onChange={handleChange}
            min={5}                    // Minimum value
            max={60}                  // Maximum value
            step={1}                   // Step increment
            valueLabelDisplay="auto"   // Display the value label
            valueLabelFormat={(value) => value === 60 ? '60 min+' : `${value} min`}
            styles = {{color:"white"}}
        />
        </ThemeProvider>
        </div>
    );


}
