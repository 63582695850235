import React, { useState, useEffect } from "react";
import DynamicInputFields from "./Components/IngredientField/IngredientFields";
import {DropDownSelect2} from "./Components/DropDownSelect/DropDownFields";
import "./RecipeExport.css";
import DisplayRecipes from "./Components/RecipeCards/CardRecipe";
import {api} from '../../constants/importantURL';
import SearchButton from "./Components/SearchButton/SearchButton";
import SimpleInputField from "./Components/RecipeOptionField/RecipeField";
import BasicTooltip from "./Components/ToolTip/ToolTips";
import ToggleButton from "./Components/ToggleButton/ToggleButton";
import {TimeSlider} from "./Components/TimeSelect/TimeToggleContainer";
import ResultsTextBox from "./Components/ResultTextBox/ResultsTextBox";
import Navbar from "../LandingPage/Navbar";
import Footer from "../LandingPage/Subsections/Footer";
export default function RecipePage() {

  const mealOptions = [
    { value: 'Dinner', label: 'Aftensmad' },
    { value: 'Lunch', label: 'Frokost' },
    { value: 'Dessert', label: 'Dessert' },
    { value: 'Drink', label: 'Drink' },
    { value: 'Breakfast', label: 'Morgenmad' },
    { value: 'Side', label: 'Tilbehør' },
  ]

  const [ingredientFieldValues, setIngredientFieldValues] = useState({});
  const [mealCategoryOptions, setMealCategoryOptions] = useState([]);
  const [recipeName, setRecipeName] = useState("");
  const [recipes, setRecipes] = useState([]);

  const [vegetarianChecked, setVegetarianChecked] = useState(false);
  const [cheapChecked, setCheapChecked] = useState(false);
  const [healthyChecked, setHealthyChecked] = useState(false);
  const [cookTime, setCookTime] = useState(60);

  async function querySearch()
  {
    var queryString = "recipes/find?";
    const searchParams = new URLSearchParams();

    if (Object.values(ingredientFieldValues).length !== 0)
    {
      searchParams.append("ingredients", Object.values(ingredientFieldValues).join(',').toLowerCase());
    }

    if (mealCategoryOptions.length !== 0)
    {
      searchParams.append("meals", mealCategoryOptions.join(',').toLowerCase());
    }
    if (recipeName && recipeName !== "" && recipeName !== " "){
      searchParams.append("recipeoption", recipeName);
    }

    if (cookTime !== "")
    {
      searchParams.append("timeoption", cookTime);
    }

    searchParams.append("vegetarian", vegetarianChecked)
    searchParams.append("healthy", healthyChecked)
    searchParams.append("cheap", cheapChecked)

    var response = await api.get(queryString + searchParams.toString());
    setRecipes(response.data);
  }

  // On page load, render recipes
    useEffect(() => {
      let ignore = false;
      if (!ignore)  querySearch()
      return () => { ignore = true; }
      },[] // eslint-disable-line react-hooks/exhaustive-deps
    );

  return (
    <div className="recipe-page">
      <Navbar />
      <div className="options-container">
        <div className="ingredient-container">
          <div className="container-header">
            <h2>Ingredienser</h2>
            <BasicTooltip tooltipText={"Tilføj ingredienser som opskrifterne skal indeholde"}/>
          </div>
          <DynamicInputFields onChange={setIngredientFieldValues} />
        </div>
        <div className="select-container">
          <div className="container-header">
            <h2>Kategorier</h2>
            <BasicTooltip tooltipText={"Vælg hvilke måltider der planlægges til"}/>
          </div>
          <DropDownSelect2 onChange={setMealCategoryOptions} options={mealOptions} />
        </div>
        <div className="right-container">
          <div className="checkbox-container">
            <div className="container-header">
                <h2>Egenskaber</h2>
                <BasicTooltip tooltipText={"Kryds af ekstra egenskaber, som opskrifterne skal have"}/>
            </div>
            <div className="checkbox-inner-container">
              <ToggleButton text={"Vegetar 🥗"} onChange={setVegetarianChecked} isChecked={vegetarianChecked} />
              <ToggleButton text={"Billigt 💰"} onChange={setCheapChecked} isChecked={cheapChecked} />
              <ToggleButton text={"Sundt 🏋️"} onChange={setHealthyChecked} isChecked={healthyChecked} />
            </div>
          </div>
          <div className="recipe-name-container" >
            <div className="container-header">
              <h2>Opskriftsnavn</h2>
              <BasicTooltip tooltipText={"Søg efter specifikke opskrifter"}/>
            </div>
            <SimpleInputField onChange={setRecipeName} />
          </div>
          <div className="time-container" >
            <div className="container-header">
              <h2>Samlet tidsforbrug</h2>
              <BasicTooltip tooltipText={"Hvor lang tid opskriften skal tage at forberede"}/>
            </div>
            <TimeSlider timeCallback={setCookTime}/>
          </div>
          <div className="search-button-container" >
            <SearchButton clicked={querySearch} text="Søg!"/>
          </div>
        </div>
      </div>
      {ResultsTextBox(recipes)}
      {DisplayRecipes(recipes)}
      <Footer />
    </div>
  );




}

