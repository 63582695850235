/* ---- Other imports ---- */
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

/* MATERIAL UI */
import { ThemeProvider, createTheme } from "@mui/material/styles";

/* ICONS */
/*import { makeStyles } from '@material-ui/core/styles';*/

/* ---- Context Providers ---- */

import { AuthProvider } from './context_providers/AuthProvider'

/* ---- Importing pages ---- */
import RecipePage from "./pages/RecipePage/RecipeExport"
import LandingPage from "./pages/LandingPage/LandingPage";


/* STATES */

function App() {

  /* MATERIAL UI THEME */

  const theme = createTheme({
    palette: {
      primary: {
        main: "#2a9461"
      },
      secondary: {
        main: "#494c7d"
      },
      brand: {
        main: "#d1d5db"
      }
    }
  });

  return (

    <AuthProvider>
      <ThemeProvider theme={theme}>

          <Router>
            <Routes>
              <Route path = "/" element = { <LandingPage /> }/>
              <Route path = "/tool" element = { <RecipePage /> }/>

              {/* }
              <Route path = "/login" element = { <LoginPage /> }/>
              <Route path = "/registration" element = { <RegistrationPage /> }/>
              <Route path = "/welcome" element = { <WelcomePage /> }/>


              <Route path = "/protected/main" element = { <ProtectedRoute role = "FREE"><NavigationBar role = "FREE"/><MainPage /></ProtectedRoute> }/>
              <Route path = "/protected/home" element = { <ProtectedRoute role = "FREE"><NavigationBar role = "FREE"/><MainPage /></ProtectedRoute> }/>
              <Route path = "/protected/financials" element = { <ProtectedRoute role = "FREE"><NavigationBar role = "FREE"/><FinancialsPage /></ProtectedRoute> }/>
              <Route path = "/protected/kitchen" element = { <ProtectedRoute role = "FREE"><NavigationBar role = "FREE"/><KitchenPage /></ProtectedRoute> }/>
              <Route path = "/protected/cleaning" element = { <ProtectedRoute role = "FREE"><NavigationBar role = "FREE"/><CleaningPage /></ProtectedRoute> }/>
              <Route path = "/protected/profile" element = { <ProtectedRoute role = "FREE"><NavigationBar role = "FREE"/><ProfilePage /></ProtectedRoute> }/>
              <Route path = "/protected/admin" element = { <ProtectedRoute role = "ADMIN"><NavigationBar role = "ADMIN"/><AdminPage /></ProtectedRoute> }/>
            */}

            </Routes>
          </Router>


      </ThemeProvider>
    </AuthProvider>
  );
}
//<div className="min-h-full h-screen flex flex-col items-center justify-center">
export default App;
