import './RecipeField.css'


export default function SimpleInputField({ onChange }) {
    return (
        <div className="input-field-reicpe-wrapper">
            <input
                type="text"
                className="input-field-recipe"
                onChange={(e) => onChange(e.target.value)}
                placeholder={"Fx Carbonara"}
            />
        </div>
    );
}