import { useState } from 'react';
import './IngredientFields.css'
import IngredientSelect from './IngredientSelect';

function InputFieldAutocomplete({ onChange }) {
    return (
            <IngredientSelect onChange={onChange}/>
    );
}

function InputFieldWithRemove({ id, onRemove, value, onChange }) {
    return (
        <div className="input-field-ingredient-wrapper">
            <input
                type="text"
                id={id}
                className="input-ingredient-field"
                value={value}
                placeholder={id === 0 ? "Fx gær" : ""}
                onChange={onChange}
                readOnly="readonly"/>
            <button className="remove-ingredient-button" onClick={() => onRemove(id)}>Fjern</button>
        </div>
    );
}

export default function DynamicInputFields({ onChange }) {
    const [fieldIds, setFieldIds] = useState([]);
    const [fieldValues, setFieldValues] = useState({});
    const [addField, setAddField] = useState("");

    function handleAddField() {

        if (addField.replaceAll(" ", "") === "") {
            return;
        }

        let newId = 0;

        if (fieldIds.length !== 0) {
            newId = Math.max(...fieldIds) + 1;
        }

        setFieldIds([...fieldIds, newId]);
        const updatedValues = {...fieldValues, [newId]:addField};

        setFieldValues(updatedValues);
        onChange(updatedValues);

    }

    function handleRemoveField(id) {
        const updatedIds = fieldIds.filter((fieldId) => fieldId !== id);
        const updatedValues = { ...fieldValues };
        delete updatedValues[id];
        setFieldIds(updatedIds);
        setFieldValues(updatedValues);
        onChange(updatedValues);
    }

    function handleFieldValueChange(id, value) {
        setFieldValues({ ...fieldValues, [id]: value });
        onChange({ ...fieldValues, [id]: value });
    }

    // <InputField key="test" id="test" value={addField} onChange={setAddField}/>
    // <InputFieldAutocomplete key="auto" id="auto" value={addField} onChange={setAddField}/>

    return (
        <div className="dynamic-input-ingredient-fields-wrapper">
            <InputFieldAutocomplete key="auto" id="auto" value={addField} onChange={setAddField}/>
            <button className="add-ingredient-button" onClick={handleAddField}>Tilføj</button>
            <div className="wrapper-for-ingredients">
                {fieldIds.map((id) => (
                    <InputFieldWithRemove
                        key={id}
                        id={id}
                        value={fieldValues[id]}
                        onChange={(e) => handleFieldValueChange(id, e.target.value)}
                        onRemove={handleRemoveField} />
                ))}
            </div>
        </div>
    );
}
