import * as React from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';

export default function BasicTooltip({tooltipText}) {
  return (
    <Tooltip title={tooltipText} enterTouchDelay={0} placement="top" leaveTouchDelay={10000}
     style={{justifyContent: "center",  marginTop:"1%", marginLeft:"3%", color:"#7864ac"}}>
        <InfoIcon />
    </Tooltip>
  );
}

export function ContactToolTip({tooltipText}) {
  return (
    <Tooltip title={<h3>{tooltipText}</h3>} enterTouchDelay={0} placement="top" leaveTouchDelay={10000}
     style={{justifyContent: "center", display: "flex", color:"white", marginRight:"10px", 
             fontSize: "200%", }}>
        <HelpTwoToneIcon />
    </Tooltip>
  );
}